import { FOOTER_TEXT } from "../../appConstants";

const Footer = () => {
    return (
      <div className="App-footer">
        {/* { 'REACT_APP_PAYMENT_URL:' + process.env.REACT_APP_PAYMENT_URL + '<br />' }
        { 'PAYMENT_URL:' + process.env.PAYMENT_URL + '<br />' } */}
        { FOOTER_TEXT }
      </div>
    );
}

export default Footer;
